import { Route, Routes } from "react-router-dom";
import About from "./About/About";
import Contact from "./Contact/Contact";
import { Home } from "./Home/Home";
import Portfolio from "./Portfolio/Portfolio";
import Layout from "./Utilities/Layout";

const App = (props) => {

  return (
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route index element={<Home/>}/>
        <Route path="portfolio" element={<Portfolio/>}/>
        <Route path="about" element={<About/>}/>
        <Route path="contact" element={<Contact/>}/>
      </Route>
    </Routes>
  )
}

export default App;
