import styles from './styles.module.css';

export const Home = (props) => {
  return (
    <div className={styles.home}>
      <div>
        <h1 className={styles.title}>Curtis Guy Russell II</h1>
        <h2 className={styles.subtitle}>Front End Engineer</h2>
      </div>
      <p className={styles.blurb}>Always improving, always learning, always delivering.</p>
    </div>
  );
};
