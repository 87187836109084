import Folio from '../Folio Items/Folio';
import styles from './styles.module.css'

const Portfolio = (props) => {

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <h1 className={styles.heading}>My Portfolio</h1>
                <p className={styles.subHeader}>Current as of July 2022</p>
            </div>
            <Folio/>
        </div>
    )
}

export default Portfolio;