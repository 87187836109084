import styles from './styles.module.css';

const ItemButton = (props) => {

    return (
        <button
        onClick={props.handleClick}
        className={props.section === props.current ? styles.currentButton : styles.otherButton}
    >
            {props.header}
    </button>
    )
}

export default ItemButton;