import {Outlet} from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import styles from './styles.module.css'

const Layout = (props) => {

    return (
        <>
            <Navbar />
            <div className={styles.pageContainer}>
                <div className={styles.stylingContainer}>
                    <div className={styles.contentContainer}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout;