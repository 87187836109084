import { useState } from 'react';
import Item from './Item';
import ItemButton from './ItemButton';
import styles from './styles.module.css'


const data = [
    {
        header: "Starcraft 2 Custom Campaign Manager",
        button: "SC2 CCM",
        description: "A project centered on an applet for Starcraft 2, this desktop-only site is focused mainly on readability, convenience, and ease of use - as well as aesthetic appeal, of course.",
        codeLink: "https://github.com/c-russell-II/ccmsite",
        codeDescription: "View the code!",
        siteLink: "http://ccmsite-mock.surge.sh/",
        siteDescription: "Non-Functional Mock Available Here",
        key: "ccmItem",
        index: 0
    },
    {
        header: "Traveller Character Generator",
        button: "Character Gen",
        description: "A passion project originally intended to be a quick random character tool, this rapidly turned into something I am very proud of - and have, on an unrelated note, spent way too much time on. Quite a bit of state management, timing of logic, and separation of concerns on display here.",
        codeLink: "https://github.com/c-russell-II/TravellerCharacterCreation",
        codeDescription: "View the TCC repo!",
        siteLink: "https://main.d1os4ggdlaqq1k.amplifyapp.com/",
        siteDescription: "Here's the Current Build!",
        key: "tccItem",
        index: 1
    }
]
const Folio = (props) => {
    const [section, setSection] = useState(0);

    return (
        <div className={styles.folioContainer}>
            <div className={styles.buttonSection}>
                {data.map((e, i) =>
                    <ItemButton
                        section={i}
                        header={e.button}
                        current={section}
                        handleClick={e => setSection(i)}
                        key={e.key}
                    />
                )}
            </div>
            <Item {...data[section]}/>
        </div>
    )
}

export default Folio