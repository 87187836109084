import styles from './styles.module.css'

const Item = (props) => {
    const {
        header,
        description,
        codeLink,
        codeDescription,
        siteLink,
        siteDescription
    } = props;

    return (
        <div>
            <h2 className={styles.sectionHeader}>{header}</h2>
            <p className={styles.text}>{description}</p>
            <div className={styles.linkSection}>
                {codeLink === 'none' ? 
                    <span className={styles.text}> {codeDescription}</span>
                    :
                    <a href={codeLink} className={styles.link}>{codeDescription}</a>
                }
                {siteLink === 'none' ?
                    <span className={styles.text}>{siteDescription}</span>
                    :
                    <a href={siteLink} className={styles.link}>{siteDescription}</a>
                }
            </div>
        </div>
    )
}

export default Item