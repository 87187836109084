import { Link, useLocation } from "react-router-dom";
import styles from './styles.module.css';

const Navbar = (props) => {
    const locationObj = useLocation();
    const location = locationObj.pathname;
    return (
        <nav className={styles.navBar}>
            <Link to="/" className={location === '/' ? styles.current : styles.other}>
                home
            </Link>
            <Link to="/about" className={location === '/about' ? styles.current : styles.other}>
                about
            </Link>
            <Link to="/portfolio" className={location === '/portfolio' ? styles.current : styles.other}>
                portfolio
            </Link>
            <Link to="/contact" className={location === '/contact' ? styles.current : styles.other}>
                contact
            </Link>
        </nav>
    )
}

export default Navbar;