import styles from './styles.module.css';

const About = (props) => {

    return (
        <div className={styles.textContainer}>
            <h1 className={styles.heading}>A little about myself -</h1>
            <p className={styles.text}>I'm a father of two, a ceritified front-end engineer, a long-time author and a GM for a tabletop RPG group that's been running since 2016. I mostly mention it because it, obviously, demonstrates commitment, dedication, and scheduling skills. Obviously. </p><br/>
            <p className={styles.text}>I mention learning and improving first, on my home page, and that deserves some elaboration. To me, the most important part of any job, any career, is to constantly get <em className={styles.slanted}>better</em> as you go. In the end, it doesn't matter where you start- the only way to get anywhere is to <em className={styles.slanted}>move forward.</em></p>
        </div>
    )
}

export default About;