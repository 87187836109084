import styles from './styles.module.css';

const Contact = (props) => {
    
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>My Contact Info</h1>
            <ul>
                <li className={styles.contactInfo}>Phone: <strong>360 932 0323</strong></li>
                <li className={styles.contactInfo}>Email: <strong>curtisgrussell@gmail.com</strong></li>
            </ul><br/>
            <p className={styles.details}>If you have an offer for work, or non-urgent questions, email is generally better.</p>
            <p className={styles.details}>For time-sensitive questions or updates on current work or agreements, call me.</p>
        </div>
    )
}

export default Contact;